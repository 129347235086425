import React from 'react';
import "./index.css"
import { addBusiness, getAllCategories } from "../../actions/business";
import {getTranslation} from "../../languages/language";
import {Button, Input, Label, Segment, Dropdown} from "semantic-ui-react";

class Form extends React.Component {
    state = {
        name: "",
        name_error: false,
        address: "",
        address_error: false,
        address_error_message: '',
        locality: "",
        locality_error: false,
        city: "",
        city_error: false,
        state: "",
        state_error: false,
        pincode: null,
        pincode_error: false,
        categories: [],
        category: '',
        category_error: false,
        phone_number: "",
        phone_number_error: false,
        email: "",
        email_error: false
        // site: ""
    };

    componentDidMount() {
        getAllCategories(this)
    }

    selectCategoryChange = (event, data) => {
        this.setState({category: data.value})
    };

    handleInputChange = event => {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    };

    render() {
        const { app } = this.props;
        const lang = localStorage.getItem('lang');
        let category_list = this.state.categories;
        const category_options = category_list.map(category => { return {key: category.value, value: category.value, text: category.labels, image: {avatar: true, src: `/Industries/${category.labels}.png`}}});
        return (
            <div className='formContainer'>
                <div className="login-form">
                    <Segment textAlign='center'>
                        <h1>{getTranslation(lang, "title", "business_form")}</h1>
                        <div className="form-block">
                            <label htmlFor="businessName">{getTranslation(lang, "business_name", "business_form")}</label>
                            {this.state.name_error ? <Label basic color='red' pointing='below'>Please enter the business name</Label> : <div/>}
                            <Input fluid type="text" name="name" onChange={this.handleInputChange} error={this.state.name_error}/>
                        </div>

                        <div className="form-block">
                            <label htmlFor="businessAddress">{getTranslation(lang, "business_address", "business_form")}</label>
                            {this.state.address_error ? <Label basic color='red' pointing='below'>{this.state.address_error_message}</Label> : <div/>}
                            <Input fluid name="address" type="text" onChange={this.handleInputChange} error={this.state.address_error}/><br />
                        </div>

                        <div className="form-block">
                            <label htmlFor="businessLocality">{getTranslation(lang, "business_locality", "business_form")}</label>
                            {this.state.locality_error ? <Label basic color='red' pointing='below'>Please enter the locality</Label> : <div/>}
                            <Input fluid name="locality" type="text" onChange={this.handleInputChange} error={this.state.locality_error}/><br />
                        </div>

                        <div className="form-block">
                            <label htmlFor="businessRegion">{getTranslation(lang, "business_region", "business_form")}</label>
                            {this.state.city_error ? <Label basic color='red' pointing='below'>Please enter the city</Label> : <div/>}
                            <Input fluid name="city" type="text" onChange={this.handleInputChange} error={this.state.city_error}/><br />
                        </div>

                        <div className="form-block">
                            <label htmlFor="businessState">{getTranslation(lang, "business_state", "business_form")}</label>
                            {this.state.state_error ? <Label basic color='red' pointing='below'>Please enter the state</Label> : <div/>}
                            <Input fluid name="state" type="text" onChange={this.handleInputChange} error={this.state.state_error}/><br />
                        </div>

                        <div className="form-block">
                            <label htmlFor="businessPIN">{getTranslation(lang, "business_pincode", "business_form")}</label>
                            {this.state.pincode_error ? <Label basic color='red' pointing='below'>Please enter the pincode</Label> : <div/>}
                            <Input fluid name="pincode" type="number" onChange={this.handleInputChange} error={this.state.pincode_error}/><br />
                        </div>

                        <div className="form-block">
                            <label htmlFor="businessCategory">{getTranslation(lang, "business_category", "business_form")}</label>
                            {this.state.category_error ? <Label basic color='red' pointing='below'>Please select a category</Label> : <div/>}
                            {category_options.length === 0 ? <Dropdown loading/> :
                                <Dropdown
                                    placeholder={getTranslation(lang, "category_select", "panel")}
                                    fluid
                                    selection
                                    options={category_options}
                                    onChange={this.selectCategoryChange}
                                    error={this.state.category_error}
                                    value={this.state.category}/>}

                        </div>

                        <div className="form-block">
                            <label htmlFor="businessPhone">{getTranslation(lang, "business_number", "business_form")}</label>
                            {this.state.phone_number_error ? <Label basic color='red' pointing='below'>Please enter the phone number</Label> : <div/>}
                            <Input fluid name="phone_number" type="text" onChange={this.handleInputChange} error={this.state.phone_number_error}/><br />
                        </div>

                        <div className="form-block">
                            <label htmlFor="businessEmail">{getTranslation(lang, "business_email", "business_form")}</label>
                            {this.state.email_error ? <Label basic color='red' pointing='below'>Please enter an email</Label> : <div/>}
                            <Input fluid name="email" type="text" onChange={this.handleInputChange} /><br />
                        </div>

                        {/*<div className="form-block">*/}
                        {/*    <label htmlFor="businessSite">Please enter website of business</label><br />*/}
                        {/*    <input name="businessSite" type="text" onChange={this.handleInputChange} /><br />*/}
                        {/*</div>*/}
                        <h3> </h3>
                        <Button id="btn-login" onClick={() => addBusiness(this, app.state.accessID)}>{getTranslation(lang, "submit", "login")}</Button><br />
            </Segment>
                </div>
            </div>
        );
    }
}

export default Form;