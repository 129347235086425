import React from "react";
import "./styles.css"
import {getUser} from "../../actions/postings";
import {getTranslation} from "../../languages/language";

class PostingInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = this.props.history.location.state
    }

    componentDidMount() {
        getUser(this)
    }

    render() {
        const { app } = this.props;
        const deadline_date = new Date (this.state.deadline);
        const created_date = new Date (this.state.created_on);
        const lang = localStorage.getItem('lang');
        return (
            <div>
                <div className="postingHeader">
                    <div className="headerRow">
                        <div className="postingID">
                            <h1 className="center">{getTranslation(lang, "posting_id", "posting_info")}: {this.state.posting_id}</h1>
                        </div>
                        <div className="businessName">
                            <h2>
                                {this.state.business_name}
                            </h2>
                        </div>
                    </div>

                </div>
                <link rel="stylesheet" href="https://www.w3schools.com/w3css/4/w3.css"/>
                <div className="w3-container">
                    <table className="w3-table-all">
                        <thead>
                        <tr className="w3-blue">
                            <th>{getTranslation(lang, "posting_title", "posting_info")}</th>
                            <th/>
                        </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td width="25%">{getTranslation(lang, "job_title", "info")}</td>
                                <td width="75%">{this.state.job_title}</td>
                            </tr>
                            <tr>
                                <td width="25%">{getTranslation(lang, "address", "info")}</td>
                                <td width="75%">{this.state.business_address}</td>
                            </tr>
                            <tr>
                                <td width="25%">{getTranslation(lang, "job_description", "info")}</td>
                                <td width="75%">{this.state.job_description}</td>
                            </tr>
                            <tr>
                                <td width="25%">{getTranslation(lang, "job_requirements", "info")}</td>
                                <td width="75%">{this.state.job_requirements}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="w3-container">
                    <table className="w3-table-all">
                        <thead>
                        <tr className="w3-blue">
                            <th>{getTranslation(lang, "application_title", "posting_info")}</th>
                            <th/>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td width="25%">{getTranslation(lang, "job_deadline", "info")}</td>
                            <td width="75%">{deadline_date.toString()}</td>
                        </tr>
                        <tr>
                            <td width="25%">{getTranslation(lang, "created_on", "info")}</td>
                            <td width="75%">{created_date.toString()}</td>
                        </tr>
                        <tr>
                            <td width="25%">{getTranslation(lang, "created_by", "posting_info")}</td>
                            <td width="75%">{this.state.employer_name}</td>
                        </tr>
                        <tr>
                            <td width="25%">{getTranslation(lang, "employer_email", "posting_info")}</td>
                            <td width="75%">
                                <a href={"mailto:" + this.state.employer_email}>{this.state.employer_email}</a>
                            </td>
                        </tr>
                        <tr>
                            <td width="25%">{getTranslation(lang, "employer_number", "posting_info")}</td>
                            <td width="75%">
                                <a href={"tel:" + this.state.employer_phone_number}>{this.state.employer_phone_number}</a>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>

        )

    }

}

export default PostingInfo