import React from 'react';
import ReactPaginate from  'react-paginate'
import './index.css'
import BusinessInfo from './BusinessInfo'

class BusinessList extends React.Component {

    render() {
        const { data, app } = this.props;
        return (
            <div className='productList'>
                {data.data.map(item => {
                    return <BusinessInfo key={item.business_id} business={item} app={app}/>
                })}
            </div>
    )
    }
}

export default BusinessList