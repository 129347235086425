import React from 'react';
import {getTranslation} from "../../languages/language";
import "./index.css"
import {Segment} from "semantic-ui-react";


class About extends React.Component {

    render() {
        const lang = localStorage.getItem('lang');
        return (
            <div>
                <div className="container-about">
                    <div className="element-block">
                        <Segment>
                            <h1>{getTranslation(lang, "user_title", "about")}</h1>
                        
                            <p>{getTranslation(lang, "user_p1", "about")}</p>
                            <p>{getTranslation(lang, "user_p2", "about")}</p>
                            <p>{getTranslation(lang, "user_p3", "about")}</p>
                            <p>{getTranslation(lang, "user_p4", "about")}</p>
                            <p>{getTranslation(lang, "user_p5", "about")}</p>
                            <p>{getTranslation(lang, "user_p6", "about")}</p>
                            <p>{getTranslation(lang, "user_p7", "about")}</p>
                        
                        </Segment>
                    </div>
                    <div className="element-block">
                        <Segment>
                        <h1>{getTranslation(lang, "business_title", "about")}</h1>
                            <p>{getTranslation(lang, "business_p1", "about")}</p>
                            <p>{getTranslation(lang, "business_p2", "about")}</p>
                            <p>{getTranslation(lang, "business_p3", "about")}</p>
                            <p>{getTranslation(lang, "business_p4", "about")}</p>
                        </Segment>
                    </div>

            
                </div>
            </div>

        )
    }
}

export default About