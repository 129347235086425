import ApiUrl from "../api/config"

export const getBusinessPostings = (comp) => {
    const url = ApiUrl + "/business/postings/" + comp.state.business_id;
    fetch(url, {credentials: 'include'})
        .then(res => {
            if (res.status === 200) {
                return res.json();
            }
        })
        .then(json => {
            if (json.length !== 0) {
                comp.setState({postings: json})
            } else {
                alert("No postings have been found. Please try again later")
            }
        })
        .catch(error => {
            console.log(error);
        });
};

export const getBusinessInfo = (comp) => {
    const url = ApiUrl + "/business/" + comp.state.business_id;
    fetch(url, {credentials: 'include'})
        .then(res => {
            if (res.status === 200) {
                return res.json();
            }
        })
        .then(json => {
            comp.setState({business_name: json.business_name, business_address: json.business_address,
                long: json.long, lat: json.lat})
        })
        .catch(error => {
            console.log(error);
        });
};

export const getUser = (comp) => {
    const url = ApiUrl + "/employer/" + comp.state.user_id;
    fetch(url, {credentials: 'include'})
        .then(res => {
            if (res.status === 200) {
                return res.json();
            }
        })
        .then(json => {
            comp.setState({employer_name: json.name, employer_email: json.email, employer_phone_number: json.phone_number})
        })
        .catch(error => {
            console.log(error);
        });
};