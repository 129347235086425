import React from "react";
import {Tab} from "semantic-ui-react";
import PostingViewTable from "./PostingViewTable";
import LocationView from "./LocationView";
import {getBusinessInfo, getBusinessPostings} from "../../actions/postings";

class PostingView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            business_id: this.props.match.params.id,
            business_name: '',
            business_address: '',
            long: null,
            lat: null,
            postings: []
        }
    }

    componentDidMount() {
        getBusinessPostings(this);
        getBusinessInfo(this);
    }
    render() {
        const panes = [
            { menuItem: 'Postings', render: () => <Tab.Pane><PostingViewTable info={this}/></Tab.Pane> },
            { menuItem: 'Location', render: () => <Tab.Pane><LocationView info={this}/></Tab.Pane> },
        ];
        return (
            <Tab menu={{attached: false, tabular: false}} panes={panes}/>
        )
    }
}

export default PostingView