import React from 'react';
import {Menu, Sidebar, Dropdown, Icon} from "semantic-ui-react";
import {getTranslation} from "../../languages/language";
import {logout} from "../../actions/user";
import './styles.css'
import { Link, withRouter } from "react-router-dom";
import {ReactFtux, ReactFtuxTooltip} from 'react-ftux'

class NavBar extends React.Component {
    state = {
        lang: 'en',
        activeItem: 'Home'
    };

    selectLanguage = (event, data) => {
        this.props.app.setState({lang: data.value});
        localStorage.setItem('lang', data.value)
    };

    handleItemClick = (e, { name }) => this.setState({ activeItem: name })

    render() {
        const { app } = this.props;
        const {activeItem} = this.state;
        if (!localStorage.getItem('lang')) {
            localStorage.setItem('lang', 'en')
        }
        if(!localStorage.getItem('firstTime')) {
            localStorage.setItem('firstTime', 'true')
        }
        const lang = localStorage.getItem('lang');
        const firsTime = localStorage.getItem('firstTime');
        return (
            <Menu tabular fluid>
                <ReactFtux
                    total={5}
                    disable={false}
                    tooltipSettings={{
                        disableCloseButton: false,
                        disableKeydownListener: false,
                        animationDuration: 0.4
                    }}
                    ftuxEnd={() => {
                        localStorage.setItem('firstTime', 'false')
                    }} />
                <Menu.Item name='Home' as={ Link }  to={app.state.userType === 'business' ? '/business-home' : '/'} active={activeItem === 'Home'} onClick={this.handleItemClick}>
                    <Icon name='home'/>
                    {getTranslation(lang, "home", "navBar")}
                </Menu.Item>

                {app.state.userType === 'user' ? <Menu.Item name='Profile' as={ Link } to='/user-profile' active={activeItem === 'Profile'} onClick={this.handleItemClick}><Icon name='user'/>{getTranslation(lang, "profile", "navBar")}</Menu.Item> : <div/>}
                {app.state.userType === 'business' ? <Menu.Item name='Profile' as={ Link } to={'/view/business-profile/' + app.state.currentUser} active={activeItem === 'Profile'} onClick={this.handleItemClick}><Icon name='user'/>{getTranslation(lang, "profile", "navBar")}</Menu.Item> : <div/>}
                {app.state.userType === 'business' ? <Menu.Item name='addBusiness' as={ Link } to='/business-form' active={activeItem === 'addBusiness'} onClick={this.handleItemClick}><Icon name='add'/>{getTranslation(lang, "add_business", "navBar")}</Menu.Item> : <div/>}
                {app.state.userType === 'business' ? <Menu.Item name='addPosting' as={ Link } to='/posting-form' active={activeItem === 'addPosting'} onClick={this.handleItemClick}><Icon name='add'/>{getTranslation(lang, "add_posting", "navBar")}</Menu.Item> : <div/>}

                {firsTime === 'true' ? <ReactFtuxTooltip zIndex={1000} step={0}>
                    <h5>Select a Language</h5>
                    <br />
                    <p>Click here to set your language of preference</p>
                </ReactFtuxTooltip> : <div/>}
                <Dropdown item text={getTranslation(lang, "languages", "navBar")}>
                    <Dropdown.Menu className='languageDropdown'>
                        <Dropdown.Item value='en' text='English' onClick={this.selectLanguage} active={localStorage.getItem('lang') === 'en'}/>
                        <Dropdown.Item value='hi' text='हिंदी' onClick={this.selectLanguage} active={localStorage.getItem('lang') === 'hi'}/>
                        {/*<Dropdown.Item value='mr' text='Marathi' onClick={this.selectLanguage} active={localStorage.getItem('lang') === 'mr'}/>*/}
                    </Dropdown.Menu>
                </Dropdown>

                {firsTime === 'true' ? <ReactFtuxTooltip scrollTo={true} zIndex={1000} step={4}>
                    <h5>Guide</h5>
                    <br />
                    <p>Click here for more information</p>
                </ReactFtuxTooltip> : <div/>}
                <Menu.Item name='Guide' as={ Link }  to={'/guide'} active={activeItem === 'Guide'} onClick={this.handleItemClick}>
                    <Icon name='info circle'/>
                    {getTranslation(lang, "guide", "navBar")}
                </Menu.Item>

                {firsTime === 'true' ? <ReactFtuxTooltip zIndex={1000} step={1}>
                    <h5>Sign In</h5>
                    <br />
                    <p>Click here to create your account right now!</p>
                </ReactFtuxTooltip> : <div/>}
                {app.state.userType ? <Menu.Item name='SignOut' onClick={() => logout(app, this)}><Icon name='sign-out'/>{getTranslation(lang, "sign_out_button", "navBar")}</Menu.Item> :
                    <Menu.Item name='Sign In'  as={ Link }  to={'/user-login'} active={activeItem === 'Sign In'} onClick={this.handleItemClick}>
                        <Icon name='sign-in'/>{getTranslation(lang, "sign_in_button", "navBar")}
                    </Menu.Item>}
            </Menu>
        );
    }


}

export default withRouter(NavBar);