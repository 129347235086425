import React from 'react';
import Map from "mapmyindia-react";
import { markers_arr } from "../../actions/markers";
import './index.css'

class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = this.props.data
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.data.clicks !== this.props.data.clicks) {
            this.setState({
                data: this.props.data.data,
                latitude: this.props.data.latitude,
                longitude: this.props.data.longitude,
                address: this.props.data.address,
                isLoaded: this.props.data.isLoaded,
                clicks: this.props.data.clicks
            });
        }
    }


    render () {
        const { app } = this.props;
        return (
            <div key={this.props.data.clicks} className='mapView'>
                <Map markers={markers_arr(this.state.data, app.state.userType)} center={[this.props.data.latitude, this.props.data.longitude]}/>
            </div>
        )

    }
}

export default Home;