import React from "react";
import {getTranslation} from "../../../languages/language";
import {Loader, Dimmer, Tab} from "semantic-ui-react";
import Map from "mapmyindia-react"

class LocationView extends React.Component {
    render() {
        const { app, info } = this.props;
        const lang = localStorage.getItem('lang');
        console.log(this.state)
        return (
            <div className="postingContainer">
                <h2>Location: {info.state.business_address}</h2>
                {info.state.lat !== null ?  <Map markers={[
                        {
                            position: [info.state.lat, info.state.long],
                            title: info.state.business_name
                        }
                    ]} center={[info.state.lat, info.state.long]}/> :
                    <Dimmer active>
                        <Loader>Loading Map</Loader>
                    </Dimmer>
                }
            </div>
        )

    }

}

export default LocationView