import React from "react";
import {removeBusiness} from "../../../../actions/views";

class BusinessItem extends React.Component {
    render() {
        const { item, list, app } = this.props;
        return (
            <tr>
                <td>
                    {item.business_name}
                </td>
                <td>
                    {item.business_address}
                </td>
                <td>
                    {item.category}
                </td>
                <td>
                    {item.pincode}
                </td>
                <td>
                    <button onClick={() => removeBusiness(list, item, app)}>remove</button>
                </td>
            </tr>
        )
    }
}

export default BusinessItem