export const markers_arr = (data, userType) => {
    if (data !== undefined) {
        return data.map(business => {
            return {
                position: [business.lat, business.long],
                draggable: false,
                title: (userType !== "business" ? business.business_address : business.name),
                onClick: e => {
                    (userType !== "business" ? window.location.href = "/view/posting/" + business.business_id : console.log("business account"))
                }
            }
        })
    }
};