import React from "react";
import {viewAddress} from "../../../actions/user";

class AddressItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: []
        }
    }

    componentDidMount() {
        viewAddress(this, this.props.app)
    }
    render() {
        const { app } = this.props;
        console.log(app)
        return (
        <p>{this.state.data.address}, {this.state.data.city_name}, {this.state.data.state_code}, {this.state.data.pincode}</p>
        )
    }
}

export default AddressItem