import React from 'react';
import "./index.css"
import Home from "../Home";
import BusinessList from "../BusinessList";
import {getCategories, getLocalities, filter} from "../../actions/business";
import { logout } from "../../actions/user";
import { withRouter }from 'react-router-dom'
import { getTranslation } from "../../languages/language";
import 'semantic-ui-css/semantic.css'
import Dropdown from "semantic-ui-react/dist/commonjs/modules/Dropdown";
import {Divider, Grid, Segment, Input, Button, Menu} from "semantic-ui-react";
import logo from "../../logo.svg"
import {ReactFtux, ReactFtuxTooltip} from "react-ftux";

class Panel extends React.Component {
    constructor(props) {
        super(props);
        this.props.history.push("/")
    }

    state = {
        data: [],
        latitude: 19.1176,
        longitude: 72.9060,
        address: '',
        clicks: 0,
        categories: [],
        selected_category: '',
        localities: [],
        selected_locality: '',
        radius: 0,
        viewType: 'Map',
        currentUser: null,
        userType: null,
    };

    componentDidMount() {
        getCategories(this);
        getLocalities(this);
    }

    handleInputChange = event => {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    };

    searchClick = async () => {
        const info = await filter(this, this.props.app.state.accessID);
        if (info !== -1) {
            this.setState({
                data: info.data,
                address: info.address,
                latitude: info.latitude,
                longitude: info.longitude,
                clicks: this.state.clicks + 1,
            });
        }
    };

    selectCategoryChange = (event, data) => {
        this.setState({selected_category: data.value})
    };

    selectLocalityChange = (event, data) => {
        this.setState({selected_locality: data.value})
    };

    selectListView = event => {
        this.setState({viewType: 'List'})
    };

    selectMapView = event => {
        this.setState({viewType: 'Map'})
    };


    handleOnClick = event => {
        window.location.href = "/user-login"
    };

    handleGuideClick = event => {
        window.location.href = "/guide"
    };

    render() {
        const { app } = this.props;
        let category_list = this.state.categories;
        let locality_list = this.state.localities;
        const lang = localStorage.getItem('lang');
        const category_options = category_list.map(category => { return {key: category.value, value: category.value, text: category.labels, image: {avatar: true, src: `/Industries/${category.labels}.png`}}});
        const locality_options = locality_list.map(locality => { return {key: locality.value, value: locality.value, text: locality.labels}});
        const firsTime = localStorage.getItem('firstTime');
        return (
            <div className="page">
                {this.state.viewType === 'Map' ? <Home data={this.state} app={app}/> : <BusinessList data={this.state} app={app}/>}
                <div className='formContainer'>
                <Segment>
                    <Grid columns={2} relaxed='very' container={true}>
                        <Grid.Column classname='column'>
                            <label htmlFor="location">{getTranslation(lang, "location_select", "panel")}</label>
                            <Input placeholder={getTranslation(lang, "location_select", "panel")} onChange={this.handleInputChange} type='text' fluid className='input' name='address'/>
                            <label htmlFor="location"> {getTranslation(lang, "category_select", "panel")} </label>
                            {category_options.length === 0 ? <Dropdown loading/> :
                                <Dropdown
                                placeholder={getTranslation(lang, "category_select", "panel")}
                                fluid
                                selection
                                options={category_options}
                                onChange={this.selectCategoryChange}
                                value={this.state.selected_category}/>}

                            <label htmlFor="location"> {getTranslation(lang, "locality_select", "panel")}</label>
                            {locality_options.length === 0 ? <Dropdown loading/> :
                                <Dropdown
                                    placeholder={getTranslation(lang, "locality_select", "panel")}
                                    fluid
                                    selection
                                    options={locality_options}
                                    onChange={this.selectLocalityChange}
                                    value={this.state.selected_locality}/>}
                            <label htmlFor="location">{getTranslation(lang, "radius_select", "panel")}</label>
                            {this.state.address === '' ? <Input disabled placeholder={getTranslation(lang, "radius_select", "panel")}/> :
                                <Input
                                    placeholder='Enter a radius'
                                    onChange={this.handleInputChange}
                                    type='number'
                                    fluid
                                    className='input'
                                    name='radius'/> }
                                    <Button  size='medium' onClick={this.searchClick}>{getTranslation(lang, "nearMe_button", "panel")}</Button>
                        </Grid.Column>
                        <Grid.Column verticalAlign='middle'>
                            {firsTime === 'true' ? <ReactFtuxTooltip pointerDirection={'left'} zIndex={1000} step={2}>
                                <h5>List View</h5>
                                <br />
                                <p>Click here to view your search results in a list form</p>
                            </ReactFtuxTooltip> : <div/>}
                            <Button id='btn-login-register' onClick={this.selectListView}>{getTranslation(lang, "list_view_button", "panel")}</Button>

                            {firsTime === 'true' ? <ReactFtuxTooltip scrollTo={true} pointerDirection={'left'} zIndex={1000} step={3}>
                                <h5>Map View</h5>
                                <br />
                                <p>Click here to view your search results on the map</p>
                            </ReactFtuxTooltip> : <div/>}
                            <Button id='btn-login-register' onClick={this.selectMapView}>{getTranslation(lang, "map_view_button", "panel")}</Button>
                            {/*<Button id='btn-login-register' onClick={this.handleGuideClick}>{getTranslation(lang, "guide", "panel")}</Button>*/}
                            {/*{!app.state.currentUser ? <Button id="btn-login-register" onClick={this.handleOnClick}>{getTranslation(lang, "sign_in_button", "panel")}</Button> :*/}
                            {/*    <div/>}*/}
                        </Grid.Column>
                    </Grid>
                    <Divider vertical/>
                </Segment>
                </div>
            </div>
        );
    }
}

export default withRouter(Panel);