import React from "react";
import ApiUrl from "../../../api/config"
import { withRouter } from "react-router-dom"

class Posting extends React.Component {

    handleClick = () => {
        const merge = Object.assign({}, this.props.posting, this.props.business_info.state);
        this.props.history.push({
            pathname: '/postingInfo/' + this.props.posting.posting_id,
            state: merge
        })
    };

    render() {
        const { posting, business_info } = this.props;
        const date = new Date (posting.deadline);

        return (
            <tr>
                <td>{posting.posting_id}</td>
                <td>
                    <a href="" onClick={this.handleClick}>
                        {posting.job_title}
                    </a>
                </td>
                <td>{business_info.state.business_address}</td>
                <td>{date.toString()}</td>
            </tr>
        )

    }

}

export default withRouter(Posting);