import ApiUrl from "../api/config"
const log = console.log;
const moment = require('moment');

export const readCookie = (app) => {
    const url =  ApiUrl + "/user/check-session";

    fetch(url, {credentials: 'include'})
        .then(res => {
            if (res.status === 200) {
                return res.json();
            }
        })
        .then(json => {
            if (json && json.currentUser) {
                app.setState({ currentUser: json.currentUser, userType: json.userType });
            }
        })
        .catch(error => {
            console.log(error);
        });
};

// Login
// A function to send a POST request with the user to be logged in
export const login = (loginComp, app) => {
    const url = ApiUrl + "/login";
    const user = loginComp.state;
    // Create our request constructor with all the parameters we need
    const request = new Request(url, {
        method: "post",
        body: JSON.stringify(user),
        headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json"
        }
    });

    // Send the request with fetch()
    fetch(request, {credentials: 'include'})
        .then(res => {
            if (res.status === 200) {
                return res.json()
            } else {
                alert('Please try a different email or password')
            }
        })
        .then(json => {
            if (json.currentUser !== undefined) {
                app.setState({ currentUser: json.currentUser, userType: json.userType });
                if (json.userType === 'business') {
                    localStorage.setItem('role', 'business');
                    loginComp.props.history.push('/business-home')
                } else {
                    localStorage.setItem('role', 'user');
                    loginComp.props.history.push('/')
                }
                console.log('login was successful');
            }
        })
        .catch(error => {
            console.log(error);
        });
};


export const logout = (app, logoutComp) => {
    const url = ApiUrl + "/logout";

    fetch(url, {credentials: "include"})
        .then(res => {
            app.setState({
                currentUser: null,
                userType: null
            });
            localStorage.removeItem('role');
            logoutComp.props.history.push('/')
        })
        .catch(error => {
            console.log(error);
        });
};

export const removeUser = (userListComp, user) => {
    const url = "http://localhost:3000/users/" + user._id;
    const request = new Request(url, {
        method: "delete",
        headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json"
        }
    });
    fetch(request, {credentials: 'include'})
        .then(function (res) {
            if (res.status === 200) {
                const filteredItems = userListComp.state.data.filter(i => {
                    return i !== user
                });
                userListComp.setState({
                    data: filteredItems
                });
                console.log(user.username + 'was removed from database');
                alert(user.username + " was removed from database")
            } else {
                console.log('failed to remove user from database')
            }
        })
};

// Add user
export const addUser = async (formComp, access_token) => {
    // the URL for the request
    const url = ApiUrl + "/signup/user";

    // The data we are going to send in our request
    const user = formComp.state;
    let error = false;

    if (user.email === '') {
        formComp.setState({email_error: true});
        error = true
    }
    if (user.password === '') {
        formComp.setState({password_error: true});
        error = true
    }
    if (user.name === '') {
        formComp.setState({name_error: true});
        error = true;
    }
    if (user.address === '') {
        formComp.setState({address_error: true, address_error_message: 'Please enter an address'});
        error = true
    } else {
        const addressInfo_url = ApiUrl + `/mapmyindia/addressInfo?address=${user.address}&access_token=${access_token}`;
        const addressInfo = await fetch(addressInfo_url);

        if (addressInfo.status === 400) {
            formComp.setState({address_error: true, address_error_message: 'Please enter a valid address'});
            error = true;
        } else {
            const response = await addressInfo.json();
            user.latitude = response.copResults.latitude;
            user.longitude = response.copResults.longitude;
            const city_check = response.copResults.city.toLowerCase() === user.city.toLowerCase();
            const state_check = response.copResults.state.toLowerCase() === user.state.toLowerCase();
            const pincode_check = response.copResults.pincode === user.pincode;
            if (!city_check || !state_check || !pincode_check){
                formComp.setState({address_error: true, address_error_message: 'Address information do not match. Please try again'});
                error = true
            }
        }
    }
    if (user.city === '') {
        formComp.setState({city_error: true});
        error = true;
    }
    if (user.state === '') {
        formComp.setState({state_error: true});
        error = true;
    }
    if (user.pincode === null) {
        formComp.setState({pincode_error: true});
        error = true;
    }
    if (!moment(user.birth_date, "YYYY-MM-DD").isValid()) {
        formComp.setState({birth_date_error: true});
        error = true;
    }
    if (user.phone_number === '') {
        formComp.setState({phone_number_error: true})
        error = true;
    }
    if (!error) {
        // Create our request constructor with all the parameters we need
        const request = new Request(url, {
            method: "post",
            body: JSON.stringify(user),
            headers: {
                Accept: "application/json, text/plain, */*",
                "Content-Type": "application/json"
            }
        });

        // Send the request with fetch()
        fetch(request)
            .then(function (res) {
                // Handle response we get from the API.
                // Usually check the error codes to see what happened.
                if (res.status === 200) {
                    console.log("Successfully added user");
                    alert("Successfully added user");
                    window.location.href = '/user-login';
                } else {
                    // If server couldn't add the user, tell the user.
                    console.log("Can not add user");
                    alert('An account has already been registered using this email. Please use a different email.')
                }
            })
            .catch(error => {
                console.log(error);
            });
    }
};

export const viewProfile = (userComp, app) => {
    const url = ApiUrl + "/user/";
    fetch(url, {credentials: "include"})
        .then(res => {
            if (res.status === 200) {
                log("successfully retrieved user");
                return res.json();
            } else {
                alert("Couldn't get user")
            }
        })
        .then(json => {
            userComp.setState({ data: json })
        })
        .catch(error => {
            log(error)
        })
};

export const viewAddress = (userComp, app) => {
    console.log(app.state)
    const url = ApiUrl + "/user/address";
    fetch(url, {credentials: "include"})
        .then(res => {
            if (res.status === 200) {
                log("successfully retrieved address");
                return res.json();
            } else {
                alert("Couldn't get address")
            }
        })
        .then(json => {
            userComp.setState({ data: json })
        })
        .catch(error => {
            log(error)
        })
};

export const viewSkills = (userComp, app) => {
    console.log(app.state)
    const url = ApiUrl + "/user/skills";
    fetch(url, {credentials: "include"})
        .then(res => {
            if (res.status === 200) {
                log("successfully retrieved skills");
                return res.json();
            } else {
                alert("Couldn't get skills")
            }
        })
        .then(json => {
            userComp.setState({ data: json })
        })
        .catch(error => {
            log(error)
        })
};