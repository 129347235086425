import React from 'react';
//import logo from './logo.svg';
import './App.css';

//import Map from "mapmyindia-react";
import {get_access_token} from "./actions/mapmyindia_api";
import {readCookie} from "./actions/user";


// Import react-router-dom to use React Router
import { Route, Switch, BrowserRouter } from 'react-router-dom';


// Importing React Components
import Home from './react-component/Home'
import Form from './react-component/Form'
import Panel from './react-component/Panel'
import BusinessLogin from './react-component/LoginRegister/BusinessLogin'
import BusinessRegister from './react-component/LoginRegister/BusinessRegister'
import UserLogin from './react-component/LoginRegister/UserLogin'
import UserRegister from './react-component/LoginRegister/UserRegister'
import PostingForm from './react-component/PostingForm'
import Profile from './react-component/Profile'
import About from './react-component/About'
import BusinessView from "./react-component/BusinessView";
import BusinessHome from "./react-component/BusinessHome";
//import BusinessList from "./react-component/BusinessList";
import PostingView from "./react-component/PostingView";
import PostingInfo from "./react-component/PostingInfo";
import { BusinessRoute, UserRoute } from "./authentication/auth";
import NavBar from "./react-component/NavBar";


class App extends React.Component {
    constructor(props) {
        super(props);
    }

    state = {
        currentUser: null,
        accessID: null,
        userType: null,
        lang: 'en'
    };

    componentDidMount() {
        readCookie(this);
        get_access_token(this)
    }

    render() {
        const { currentUser, userType, accessID } = this.state;
        return (
            <div className="page">
                <BrowserRouter>
                    <NavBar app={this} />
                    <Switch>
                        <Route exact path='/' render={({ history })=> (<Panel history={history} app={this}/>)}/>
                        {/*<Route*/}
                        {/*    exact path={["/business-home", "/business-login"]}*/}
                        {/*    render={({ history }) => (*/}
                        {/*        <div>*/}
                        {/*            {(currentUser && userType === "business") ? <BusinessHome history={history} app={this} /> : <BusinessLogin history={history} app={this} />}*/}
                        {/*        </div>*/}
                        {/*    )} />*/}

                        {/*<Route path='/business-home' render={({history}) => (<BusinessHome history={history} app={this} />)}/>*/}
                        <BusinessRoute path='/business-home' component={BusinessHome} app={this}/>
                        <Route exact path='/business-login' render={({ history }) => (<BusinessLogin history={history} app={this}/>)}/>
                        <Route exact path='/business-register' render={()=> (<BusinessRegister app={this}/>)}/>
                        <Route exact path='/user-login' render={({ history })=> (<UserLogin history={history} app = {this}/>)}/>
                        <Route exact path='/user-register' render={()=> (<UserRegister app={this}/>)}/>
                        <BusinessRoute path='/business-form' component={Form} app={this}/>}/>
                        <BusinessRoute path='/posting-form' component={PostingForm} app={this}/>
                        <UserRoute path='/user-profile' component={Profile} app={this}/>
                        <Route exact path='/guide' render={() => (<About app={this}/>)}/>
                        <BusinessRoute path='/view/business-profile/:id' component={BusinessView} app={this}/>
                        <Route exact path='/view/posting/:id' render={(matchProps) => (<PostingView {...matchProps} app={this} />)}/>
                        <Route exact path='/postingInfo/:id' render={(matchProps) => (<PostingInfo {...matchProps} app={this} />)}/>
                    {/* <Route
                        exact path={['/business-form', '/posting-form']}
                        render={({ history }) => (
                            <div className="app">
                                {(currentUser && userType === "business") ? <Form history={history} app={this}/> : <PostingForm history={history} app={this}/>}
                            </div>
                        )}
                    /> */}
                        <Route render={() => <div>404 Not Found</div>}/>
                    </Switch>
                </BrowserRouter>
                {/*<BusinessList/>*/}
                {/* <Panel app={this}/> */}
                {/* <Form/> */}
                {/*<Search/>*/}
            </div>

        );
    }
}

export default App;