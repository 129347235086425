import React from "react";
import { viewBusinesses } from "../../../actions/views";
import BusinessItem from "./BusinessItem";
import {getTranslation} from "../../../languages/language";

class BusinessTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: []
        }
    }

    componentDidMount() {
        console.log(this.props.app)
        viewBusinesses(this, this.props.app)
    }


    render() {
        const { app, lang } = this.props;
        return (
            <div>
                <table>
                    <thead>
                    <tr>
                        <th>{getTranslation(lang, "name", "info")}</th>
                        <th>{getTranslation(lang, "address", "info")}</th>
                        <th>{getTranslation(lang, "category", "info")}</th>
                        <th>{getTranslation(lang, "pincode", "info")}</th>
                        <th>{getTranslation(lang, "remove", "info")}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.state.data.map(item => (
                        <BusinessItem item={item} list={this} app={app}/>)
                    )}
                    </tbody>
                </table>
            </div>
        )

    }

}

export default BusinessTable