import ApiUrl from "../api/config"
import { findCenter } from "../actions/business"
const log = console.log;

export const viewBusinesses = (allBusinessComp, app) => {
    const url = ApiUrl + "/view/businesses/";
    fetch(url, {credentials: "include"})
        .then(res => {
            if (res.status === 200) {
                log("successfully retrieved all cart items");
                return res.json();
            } else {
                alert("Couldn't get businesses")
            }
        })
        .then(json => {
            allBusinessComp.setState({ data: json })
        })
        .catch(error => {
            log(error)
        })
};

export const viewPostings = (allPostingComp, app) => {
    const url = ApiUrl + "/view/postings";
    fetch(url, {credentials: "include"})
        .then(res => {
            if (res.status === 200) {
                log("successfully retrieved all postings");
                return res.json();
            } else {
                alert("Couldn't get postings")
            }
        })
        .then(json => {
            allPostingComp.setState({ data: json })
        })
        .catch(error => {
            log(error)
        })
};

export const removeBusiness = (itemListComp, item, app) => {
    console.log(itemListComp, item, app)
    const filteredItems = itemListComp.state.data.filter(i => {
        return i !== item
    });

    itemListComp.setState({
        data: filteredItems
    });

    const url = ApiUrl + "/business/" + item.business_id;
    const request = new Request(url, {
        method: "delete",
        headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json"
        }
    });
    fetch(request, {credentials: 'include'})
        .then(function (res) {
            if (res.status === 200) {
                console.log('business was removed from cart');
                alert(item.business_name + " was removed from your businesses")
            } else {
                console.log('failed to remove business from database')
            }
        })
};

export const removePosting = (itemListComp, item, app) => {
    console.log(itemListComp, item, app);
    const filteredItems = itemListComp.state.data.filter(i => {
        return i !== item
    });

    itemListComp.setState({
        data: filteredItems
    });

    const url = ApiUrl + "/posting/" + item.posting_id;
    const request = new Request(url, {
        method: "delete",
        headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json"
        }
    });
    fetch(request, {credentials: 'include'})
        .then(function (res) {
            if (res.status === 200) {
                console.log('posting was removed from database');
                alert(item.job_title + " was removed from your postings")
            } else {
                console.log('failed to remove posting from postings')
            }
        })
};

export const filter_users = async (comp) => {
    const filter_search = comp.state;
    let conditions = [];
    // Check if all fields are empty
    if (filter_search.business_id === '' || filter_search.radius === 0) {
        alert('please fill in the fields to find users near you');
        return -1
    }

    if (filter_search.business_id !== '' && filter_search.radius !== 0) {
        conditions.push(`ST_DWithin(Geography(coordinates), Geography((SELECT coordinates FROM business where business_id=${filter_search.business_id})), ${filter_search.radius}, true)`);
    }

    // if (filter_search.skills !== '') {
    //     const skills_arr = filter_search.skills.split(',');
    //     const skills_join = "'" + skills_arr.join("','") + "'";
    //     conditions.push(`skill in (${skills_join})`)
    // }

    const where_clause = conditions.join(' AND ');

    const search_url = ApiUrl + `/user/filter?filter=${where_clause}`;
    const response = await fetch(search_url);
    const result = await response.json();
    const center = findCenter(result);
    return { data: result, latitude: center.lat, longitude: center.long }
};

export const viewContactInfo = (comp, business) => {
    const url = ApiUrl + "/business/contact_info/" + business.business_id;
    fetch(url, {credentials: "include"})
        .then(res => {
            if (res.status === 200) {
                log("successfully retrieved all cart items");
                return res.json();
            } else {
                alert("Couldn't get businesses")
            }
        })
        .then(json => {
            comp.setState({ phone_numbers: json.phone_numbers, email: json.email})
        })
        .catch(error => {
            log(error)
        })
};