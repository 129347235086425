import './index.css'
import React from "react";
import { viewContactInfo } from "../../../actions/views";
import { withRouter } from "react-router-dom"
import {getTranslation} from "../../../languages/language";

class BusinessInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            phone_numbers: [],
            email: []
        }
    }

    componentDidMount() {
        if (this.props.app.state.userType !== 'business') {
            viewContactInfo(this, this.props.business)
        }
    }


    handleClick = () => {
        if (this.props.app.state.userType !== 'business') {
            window.location.href = '/view/posting/' + this.props.business.business_id;
        }

    };

    render() {
        const { business, app } = this.props;
        const lang = localStorage.getItem('lang')
        if (app.state.userType !== 'business') {
            return (
                <div key={business.business_id} className='business' onClick={this.handleClick}>
                    <h2>{business.business_name}</h2>
                    <div>
                        <p><strong>{getTranslation(lang, "address", "info")}:</strong> {business.business_address}</p>
                        <p><strong>{getTranslation(lang, "category", "info")}:</strong> {business.category}</p>
                        <strong>{getTranslation(lang, "number", "info")}: </strong>
                        <ul>
                            {this.state.phone_numbers.map(number => {
                                return <li> <a href={"tel:" + number.phone}>{number.phone}</a></li>
                        })}
                        </ul>
                        <strong>{getTranslation(lang, "email", "info")}: </strong>
                        <ul>
                            {this.state.email.map(email => {
                                return <li> <a href={"mailto:" + email.email}>{email.email}</a></li>
                            })}
                        </ul>
                    </div>
                </div>
            )
        } else {
            return (
                <div key={business.user_id} className='business' onClick={this.handleClick}>
                    <h2>{business.name}</h2>
                    <div>
                        <p><strong>{getTranslation(lang, "email", "info")}:</strong> {business.email}</p>
                        <p><strong>{getTranslation(lang, "number", "info")}:</strong> {business.phone_number}</p>
                    </div>
                </div>
            )
        }


    }

}

export default BusinessInfo;