import ApiUrl from "../api/config"
const log = console.log;
const moment = require('moment');


export const getCategories = (comp) => {
    fetch(ApiUrl + '/current-categories')
        .then(res => {
            if (res.status === 200) {
                console.log('got all categories');
                return res.json()
            } else {
                console.log("could not find categories")
            }
        })
        .then(json => {
            const options = json.map(type => {
                return {
                    value: type.category,
                    labels: type.category
                }
            });
            comp.setState({categories: options})
        })
        .catch(error => {
            console.log(error)
        })
};

export const getAllCategories = (comp) => {
    fetch(ApiUrl + '/all-categories')
        .then(res => {
            if (res.status === 200) {
                console.log('got all categories');
                return res.json()
            } else {
                console.log("could not find categories")
            }
        })
        .then(json => {
            const options = json.map(type => {
                return {
                    value: type.industry_name,
                    labels: type.industry_name
                }
            });
            comp.setState({categories: options})
        })
        .catch(error => {
            console.log(error)
        })
};

export const getLocalities = (comp) => {
    fetch(ApiUrl + '/localities')
        .then(res => {
            if (res.status === 200) {
                console.log('got all localities');
                return res.json()
            } else {
                console.log("could not find localities")
            }
        })
        .then(json => {
            const options = json.map(type => {
                return {
                    value: type.locality_name,
                    labels: type.locality_name
                }
            });
            comp.setState({localities: options})
        })
        .catch(error => {
            console.log(error)
        })
};

export const getAffiliations = (comp) => {
    fetch(ApiUrl + '/view/businesses', {credentials: 'include'})
        .then(res => {
            if (res.status === 200) {
                console.log('got all businesses');
                return res.json()
            } else {
                console.log("could not find businesses")
            }
        })
        .then(json => {
            const options = json.map(type => {
                return {
                    value: type.business_id,
                    labels: type.business_name
                }
            });
            comp.setState({businesses: options})
        })
        .catch(error => {
            console.log(error)
        })
};

export const filter = async (comp, access_token) => {
    const filter_search = comp.state;
    const filter = {};
    // Check if all fields are empty
    if (filter_search.address === '' && filter_search.radius === 0 && filter_search.selected_category === '' && filter_search.selected_locality === '') {
        alert('please fill in the fields to find businesses near you');
        return -1
    }
    // Check if location and radius is provided
    if (filter_search.address === '' && filter_search.radius !== 0) {
        alert ('please enter your location with your radius');
        return -1
    } else if (filter_search.address !== '' && filter_search.radius === 0) {
        alert('please select a radius with your location');
        return -1
    }
    // User wants to find businesses around a certain radius
    if (filter_search.address !== '' && filter_search.radius !== 0) {
        const addressInfo_url =  ApiUrl + `/mapmyindia/addressInfo?address=${filter_search.address}&access_token=${access_token}`;
        const addressInfo = await fetch(addressInfo_url);

        if (addressInfo.status === 400) {
            alert('Invalid address. Please try again');
            return -1
        } else {
            const response = await addressInfo.json();
            filter.lat = response.copResults.latitude;
            filter.long = response.copResults.longitude;
            filter.radius = filter_search.radius
        }
    }
    if (filter_search.selected_category !== '') {
        filter.category = filter_search.selected_category
    }
    if (filter_search.selected_locality !== '') {
        filter.locality = filter_search.selected_locality
    }
    const params = new URLSearchParams(filter).toString();
    const search_url = ApiUrl + `/filter?${params}`;
    const response = await fetch(search_url);
    const result = await response.json();
    const center = findCenter(result);
    if (result.length === 0) {
        alert('there seems to be no businesses near your area')
        return -1
    }
    return { address: filter_search.address, data: result, latitude: center.lat, longitude: center.long }
};

export const findCenter = (data) => {
    let lat_sum = 0;
    let long_sum = 0;
    data.forEach(business => {
        lat_sum += business.lat;
        long_sum += business.long;
    });
    return {
        lat: lat_sum / data.length,
        long: long_sum / data.length
    }

};
// Add posting
export const addPosting = (formComp) => {
    // the URL for the request
    const url = ApiUrl + "/employer/posting";

    // The data we are going to send in our request
    const posting = formComp.state;
    let error = false;

    if (!moment(posting.deadline_date, "YYYY-MM-DD").isValid()) {
        formComp.setState({deadline_date_error: true});
        error = true;
    }
    if (!moment(posting.deadline_time, "hh:mm").isValid()) {
        formComp.setState({deadline_time_error: true});
        error = true;
    }
    if (posting.business_id === '') {
        formComp.setState({business_id_error: true});
        error = true;
    }
    if (posting.job_title === '') {
        formComp.setState({job_title_error: true});
        error = true;
    }
    if(posting.job_description === '') {
        formComp.setState({job_description_error: true});
        error = true;
    }
    if(posting.job_requirements === '') {
        formComp.setState({job_requirements_error: true});
        error = true;
    }

    if (!error) {
        // Create our request constructor with all the parameters we need
        const request = new Request(url, {
            method: "post",
            body: JSON.stringify(posting),
            headers: {
                Accept: "application/json, text/plain, */*",
                "Content-Type": "application/json"
            }
        });

        // Send the request with fetch()
        fetch(request, {credentials: 'include'})
            .then(function (res) {
                // Handle response we get from the API.
                // Usually check the error codes to see what happened.
                if (res.status === 200) {
                    console.log("Successfully added posting");
                    alert("Successfully added posting");
                    window.location.href = '/business-home';
                } else {
                    // If server couldn't add the posting, tell the user.
                    console.log("Can not add posting");
                    console.log(res);
                    alert('An issue occurred while adding the posting. Please ensure that all fields are filled and it is for an existing business.')
                }
            })
            .catch(error => {
                console.log(error);
            });
    }
};

// Add business
export const addBusiness = async (formComp, access_token) => {
    // the URL for the request
    const url = ApiUrl + "/business";

    // The data we are going to send in our request
    const business = formComp.state;
    let error = false;

    if (business.name === '') {
        formComp.setState({name_error: true});
        error = true;
    }
    if (business.address === '') {
        formComp.setState({address_error: true, address_error_message: 'Please enter an address'});
        error = true
    } else {
        const addressInfo_url = ApiUrl + `/mapmyindia/addressInfo?address=${business.address}&access_token=${access_token}`;
        const addressInfo = await fetch(addressInfo_url);

        if (addressInfo.status === 400) {
            formComp.setState({address_error: true, address_error_message: 'Please enter a valid address'});
            error = true;
        } else {
            const response = await addressInfo.json();
            business.latitude = response.copResults.latitude;
            business.longitude = response.copResults.longitude;
            const locality_check = response.copResults.locality.toLowerCase() === business.locality.toLowerCase();
            const city_check = response.copResults.city.toLowerCase() === business.city.toLowerCase();
            const state_check = response.copResults.state.toLowerCase() === business.state.toLowerCase();
            const pincode_check = response.copResults.pincode === business.pincode;
            if (!locality_check || !city_check || !state_check || !pincode_check){
                formComp.setState({address_error: true, address_error_message: 'Address information do not match. Please try again'});
                error = true
            }
        }
    }
    if (business.locality === '') {
        formComp.setState({locality_error: true});
        error = true;
    }
    if (business.city === '') {
        formComp.setState({city_error: true});
        error = true;
    }
    if (business.state === '') {
        formComp.setState({state_error: true});
        error = true;
    }
    if (business.pincode === null) {
        formComp.setState({pincode_error: true});
        error = true;
    }
    if (business.category === '') {
        formComp.setState({category_error: true});
        error = true;
    }
    if (business.phone_number === '') {
        formComp.setState({phone_number_error: true});
        error = true;
    }
    if (business.email === '') {
        formComp.setState({email_error: true});
        error = true;
    }
    if (!error) {
        // Create our request constructor with all the parameters we need
        const request = new Request(url, {
            method: "post",
            body: JSON.stringify(business),
            headers: {
                Accept: "application/json, text/plain, */*",
                "Content-Type": "application/json"
            }
        });

        // Send the request with fetch()
        fetch(request, {credentials: 'include'})
            .then(function (res) {
                // Handle response we get from the API.
                // Usually check the error codes to see what happened.
                if (res.status === 200) {
                    console.log("Successfully added business");
                    alert("Successfully added business");
                    window.location.href = '/business-home';
                } else {
                    // If server couldn't add the posting, tell the user.
                    console.log("Can not add business");
                    alert('An issue occurred while adding the business. Please ensure that all fields are filled.')
                }
            })
            .catch(error => {
                console.log(error);
            });
    }



};

// Login


export const removeUser = (userListComp, user) => {
    const url = "http://localhost:3000/business-users/" + user._id;
    const request = new Request(url, {
        method: "delete",
        headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json"
        }
    });
    fetch(request, {credentials: 'include'})
        .then(function (res) {
            if (res.status === 200) {
                const filteredItems = userListComp.state.data.filter(i => {
                    return i !== user
                });
                userListComp.setState({
                    data: filteredItems
                });
                console.log(user.username + 'was removed from database');
                alert(user.username + " was removed from database")
            } else {
                console.log('failed to remove user from database')
            }
        })
};

// Add user
export const addUser = (formComp) => {
    // the URL for the request
    const url = ApiUrl + "/signup/business";

    // The data we are going to send in our request
    const user = formComp.state;
    let error = false;

    if (user.email === '') {
        formComp.setState({email_error: true});
        error = true
    }
    if (user.password === '') {
        formComp.setState({password_error: true});
        error = true
    }
    if (user.name === '') {
        formComp.setState({name_error: true});
        error = true;
    }
    if (!moment(user.birth_date, "YYYY-MM-DD").isValid()) {
        formComp.setState({birth_date_error: true});
        error = true;
    }
    if (user.phone_number === '') {
        formComp.setState({phone_number_error: true})
        error = true;
    }
    if (!error) {
        // Create our request constructor with all the parameters we need
        const request = new Request(url, {
            method: "post",
            body: JSON.stringify(user),
            headers: {
                Accept: "application/json, text/plain, */*",
                "Content-Type": "application/json"
            }
        });
        // Send the request with fetch()
        fetch(request)
            .then(function (res) {
                // Handle response we get from the API.
                // Usually check the error codes to see what happened.
                if (res.status === 200) {
                    console.log("Successfully added user");
                    alert("Successfully added user");
                    window.location.href = '/business-login';
                } else {
                    // If server couldn't add the business, tell the user.
                    console.log("Can not add user");
                    alert('An account has already been registered using this email. Please use a different email.')
                }
            })
            .catch(error => {
                console.log(error);
            });
    }
};

export const getAllUsers = (usersComp) => {
    // the URL for the request
    const url = "http://localhost:3000/business-users";
    fetch(url, {credentials: 'include'})
    .then((res) => {
        if (res.status === 200){
            log("successfully retrieved users");
            return res.json()
        } else {
            alert('Could not get users');
        }
    }).then(json => {
        usersComp.setState({ data: json, isLoaded: true})
    }).catch(error => {
        log(error);
    })
};
