export const languages = [
    { code: "en", name: "English" },
    { code: "hi", name: "Hindi"},
    { code: "mr", name: "Marathi"}
];

const translations = {
  en: {
      panel: {
          language_choice: "Select a language",
          location_select: "Enter Your Location in English",
          category_select: "Select a Category",
          locality_select: "Select a Locality",
          radius_select: "Enter a Radius",
          list_view_button: "List View",
          map_view_button: "Map View",
          sign_in_button: "Sign In",
          nearMe_button: "What's near me?",
          guide: "Guide"
      },
      business_form: {
          title: "Add New Business",
          business_name: "Please enter name of business",
          business_address: "Please enter address of business",
          business_locality: "Please enter locality of business",
          business_region: "Please enter region of business",
          business_state: "Please enter state of business",
          business_pincode: "Please enter PIN of business",
          business_category: "Please enter category of business",
          business_number: "Please enter phone number of business",
          business_email: "Please enter email of business"
      },
      posting_form: {
          title: "Add New Posting",
          business_name: "Select a business",
          job_title: "Enter job title:",
          deadline_date: "Enter deadline date: (YYYY-MM-DD)",
          deadline_time: "Enter deadline time: (hh:mm)",
          job_description: "Enter job description:",
          job_requirements: "Enter short description of job requirements:"
      },
      business_posting_view: {
          business_title: "All your Businesses",
          posting_title: "All your Postings",
      },
      info: {
          email: "Email",
          password: "Password",
          name: "Name",
          address: "Address",
          city: "City",
          state: "State",
          category: "Category",
          pincode: "Pincode",
          remove: "Remove?",
          job_title: "Job Title",
          job_description: "Job Description",
          job_requirements: "Job Requirements",
          job_deadline: "Deadline",
          created_on: "Created On",
          date_of_birth: "Date of birth",
          number: "Phone Number"
      },
      login: {
          business_title: "Business Login",
          submit: "Submit",
          no_account: "Don't have an account? Click here to sign up!",
          register: "Register",
          login_user: "Login as user",
          user_title: "User Login",
          login_business: "Login as business",
          have_account: "Already have an account? Click here to login!",
          user_register: "Register as a user",
          business_register: "Register as a business",
          login_button: "Login"
      },
      posting_info: {
          posting_id: "Posting ID",
          posting_title: "Job Posting Information",
          application_title: "Application Information",
          created_by: "Created By",
          employer_email: "Employer Email",
          employer_number: "Employer Phone Number"
      },
      navBar: {
          home: "Home",
          profile: "Profile",
          add_business: "Add a Business",
          add_posting: "Add a Posting",
          sign_out_button: "Sign Out",
          sign_in_button: "Sign In",
          languages: "Languages",
          guide: "How do I use this?"
      },
      about: {
          user_title: "User guide:",
          user_p1: "You can view your profile by pressing the view profile button.",
          user_p2: "You can search for businesses in a few ways:",
          user_p3: "1) Enter an address and radius to search within that radius around it",
          user_p4: "2) Search by category by selecting one",
          user_p5: "3) Search by locality by selecting one",
          user_p6: "The default is the map view, clicking on a marker on the map will bring you to the business page with all the job postings available.",
          user_p7: "Clicking list view will show the business as a list, with some basic information, clicking on one of the list items will bring you to the business page with all the job postings available.",
          business_title: "Business guide:",
          business_p1: "You can add a new business by selecting Add a business and filling out a form.",
          business_p2: "You can add a job posting for one of your businesses by selecting Add a posting and filling out a form.",
          business_p3: "You can view your business and all postings and delete them by going to your profile.",
          business_p4: "You can view non-business users in a radius around one of your businesses and clicking What's near me?"
      }
  },
    hi: {
        panel: {
            language_choice: "भाषा चुनें",
            location_select: "अपना स्थान अंग्रेजी में दर्ज करें",
            category_select: "एक वर्ग का चयन करें",
            locality_select: "एक इलाके का चयन करें",
            radius_select: "एक दायरा दर्ज करें",
            list_view_button: "सूची दृश्य",
            map_view_button: "नक्शा देखें",
            sign_in_button: "साइन इन करें",
            nearMe_button: "मेरे पास क्या है?",
            guide: "मार्गदर्शक"
        },
        business_form: {
            title: "नया व्यापार जोड़ें",
            business_name: "कृपया व्यवसाय का नाम दर्ज करें",
            business_address: "कृपया व्यवसाय का पता दर्ज करें",
            business_locality: "कृपया व्यवसाय के इलाके में प्रवेश करें",
            business_region: "कृपया व्यवसाय का क्षेत्र दर्ज करें",
            business_state: "कृपया व्यवसाय की स्थिति दर्ज करें",
            business_pincode: "कृपया व्यवसाय का पिन दर्ज करें",
            business_category: "कृपया व्यवसाय की श्रेणी डालें",
            business_number: "कृपया व्यवसाय का फ़ोन नंबर दर्ज करें",
            business_email: "कृपया व्यवसाय का ईमेल दर्ज करें"
        },
        posting_form: {
            title: "नई पोस्टिंग जोड़ें",
            business_name: "एक व्यवसाय का चयन करें:",
            job_title: "नौकरी का शीर्षक दर्ज करें:",
            deadline_date: "अंतिम तिथि दर्ज करें: (YYYY-MM-DD)",
            deadline_time: "समय सीमा दर्ज करें: (hh: mm)",
            job_description: "नौकरी का विवरण दर्ज करें:",
            job_requirements: "नौकरी की आवश्यकताओं का संक्षिप्त विवरण दर्ज करें:"
        },
        business_posting_view: {
            business_title: "आपके सभी व्यवसाय",
            posting_title: "आपकी सभी पोस्टिंग",
        },
        info: {
            email: "ईमेल",
            password: "कुंजिका",
            name: "नाम",
            address: "पता",
            city: "शहर",
            state: "राज्य",
            category: "वर्ग",
            pincode: "पिन कोड",
            remove: "हटाना?",
            job_title: "नौकरी का नाम",
            job_description: "नौकरी का विवरण",
            job_requirements: "नौकरी की आवश्यकताएँ",
            job_deadline: "समयसीमा",
            created_on: "को निर्मित",
            date_of_birth: "जन्म की तारीख",
            number: "फ़ोन नंबर"
        },
        login: {
            business_title: "व्यापार लॉगिन",
            submit: "प्रस्तुत",
            no_account: "खाता नहीं है? साइन अप करने के लिए यहां क्लिक करें!",
            register: "रजिस्टर करें",
            login_user: "उपयोगकर्ता के रूप में लॉगिन करें",
            user_title: "उपयोगकर्ता लॉगिन",
            login_business: "व्यवसाय के रूप में लॉगिन करें",
            have_account: "क्या आपके पास पहले से एक खाता मौजूद है? लॉग इन करने के लिए यहां क्लिक करें!",
            user_register: "एक उपयोगकर्ता के रूप में पंजीकृत करें",
            business_register: "एक व्यवसाय के रूप में पंजीकृत करें",
            login_button: "लॉग इन करें"
        },
        posting_info: {
            posting_id: "पोस्टिंग आईडी",
            posting_title: "जॉब पोस्टिंग की जानकारी",
            application_title: "आवेदन की सूचना",
            created_by: "के द्वारा बनाई गई",
            employer_email: "नियोक्ता ईमेल",
            employer_number: "नियोक्ता का फोन नंबर"
        },
        navBar: {
            home: "घर",
            profile: "प्रोफ़ाइल",
            add_business: "एक व्यवसाय जोड़ें",
            add_posting: "एक पोस्टिंग जोड़ें",
            sign_out_button: "प्रस्थान करें",
            sign_in_button: "साइन इन करें",
            languages: "भाषा",
            guide: "गाइड"
        },
        about: {
            user_title: "उपयोगकर्ता गाइड:",
            user_p1: "आप व्यू प्रोफ़ाइल बटन दबाकर अपनी प्रोफ़ाइल देख सकते हैं।",
            user_p2: "आप निम्नलिखित तरीकों से व्यवसायों की खोज कर सकते हैं:",
            user_p3: "1) संबंधित स्थान का पता दर्ज करें और दायरा दर्ज करें जिसके भीतर खोजना चाहें",
            user_p4: "2) एक वर्ग का चयन करके श्रेणी के आधार पर खोजें ",
            user_p5: "3) एक इलाके का चयन करके स्थानीयता द्वारा खोजें  ",
            user_p6: "डिफ़ॉल्ट मानचित्र दृश्य है, मानचित्र पर एक मार्कर पर क्लिक करने से आपको सभी नौकरी पोस्टिंग के साथ व्यापार पृष्ठ पर लाया जाएगा।",
            user_p7: "क्लिकिंग लिस्ट दृश्य व्यवसाय को एक सूची के रूप में दिखाएगा, कुछ बुनियादी जानकारी के साथ, सूची आइटमों में से एक पर क्लिक करने से आपको सभी नौकरी पोस्टिंग के साथ व्यापार पृष्ठ पर लाया जाएगा।",
            business_title: "व्यवसाय गाइड:",
            business_p1: "आप एक व्यवसाय जोड़ें और एक फ़ॉर्म भरकर एक नया व्यवसाय जोड़ सकते हैं।",
            business_p2: "आप एक पोस्ट जोड़ें और एक फ़ॉर्म भरकर अपने किसी व्यवसाय के लिए नौकरी पोस्टिंग जोड़ सकते हैं।",
            business_p3: "आप अपने व्यवसाय और सभी पोस्टिंग को देख सकते हैं और अपनी प्रोफ़ाइल पर जाकर उन्हें हटा सकते हैं।",
            business_p4: "आप अपने किसी एक व्यवसाय के आस-पास गैर-व्यावसायिक उपयोगकर्ताओं को एक दायरे में देख सकते हैं और मेरे पास क्या है?"
        }
    }
};

export const getTranslation = (lang, text, component_name) => {
    return translations[lang][component_name][text];
};