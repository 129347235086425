import React from 'react';
import "./index.css"
import { addUser } from "../../../actions/business";
import { Link } from "react-router-dom";
import {getTranslation} from "../../../languages/language";
import {Button, Dropdown, Input, Label, Segment} from "semantic-ui-react";

class BusinessRegister extends React.Component {
    state = {
        email: "",
        email_error: false,
        password: "",
        password_error: false,
        name: "",
        name_error: false,
        phone_number: "",
        phone_number_error: false,
        birth_date: "",
        birth_date_error: false
    };

    handleInputChange = event => {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    };

    handleOnClick = event => {
        window.location.href = "/business-login"
    };


    render() {
        //const { app } = this.props
        const lang = localStorage.getItem('lang');
        return (
                <div className="container-login">
                        <div className="login-form">
                            <h1>Register as a business</h1>
                            <div className="form-block">
                                <label>{getTranslation(lang, "email", "info")}:</label>
                                {this.state.email_error ? <Label basic color='red' pointing='below'>Please enter an email</Label> : <div/>}
                                <Input type="text" name="email" onChange={this.handleInputChange} error={this.state.email_error}/><br />
                            </div>
                            <div className="form-block">
                                <label>{getTranslation(lang, "password", "info")}:</label>
                                {this.state.password_error ? <Label basic color='red' pointing='below'>Please enter your password</Label> : <div/>}
                                <Input type="text" name="password" onChange={this.handleInputChange} error={this.state.password_error}/><br />
                            </div>
                            <div className="form-block">
                                <label>{getTranslation(lang, "name", "info")}: </label>
                                {this.state.name_error ? <Label basic color='red' pointing='below'>Please enter your name</Label> : <div/>}
                                <Input type="text" name="name" onChange={this.handleInputChange} error={this.state.name_error}/><br />
                            </div>
                            <div className="form-block">
                                <label>{getTranslation(lang, "date_of_birth", "info")}: </label>
                                {this.state.birth_date_error ? <Label basic color='red' pointing='below'>Please enter your birth date with the format YYYY-MM-DD</Label> : <div/>}
                                <Input type="text" name="birth_date" onChange={this.handleInputChange} error={this.state.birth_date_error}/><br />
                            </div>
                            <div className="form-block">
                                <label>{getTranslation(lang, "number", "info")}: </label>
                                {this.state.phone_number_error ? <Label basic color='red' pointing='below'>Please enter your phone number</Label> : <div/>}
                                <Input type="text" name="phone_number" onChange={this.handleInputChange} error={this.state.phone_number_error}/><br />
                            </div>
                            <button id="btn-login" onClick={() => addUser(this)}>{getTranslation(lang, "submit", "login")}</button><br />
                            <p>{getTranslation(lang, "have_account", "login")}</p><br />

                            <button id="btn-login-register" onClick={this.handleOnClick}>{getTranslation(lang, "login_button", "login")}</button><br/>
                            <Link to="/user-register">{getTranslation(lang, "user_register", "login")}</Link>
                        </div>
                    
                </div>



        )
    }
}

export default BusinessRegister