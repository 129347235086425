import React from 'react';
import Home from "../Home";
import { getAffiliations } from "../../actions/business";
import { filter_users } from "../../actions/views";
import { withRouter }from 'react-router-dom'
import BusinessList from "../BusinessList";
import Dropdown from "semantic-ui-react/dist/commonjs/modules/Dropdown";
import { Divider, Grid, Segment, Input, Button } from "semantic-ui-react";
import {getTranslation} from "../../languages/language";

class BusinessHome extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            businesses: [],
            business_id: '',
            skills: '',
            latitude: 19.1176,
            longitude: 72.9060,
            address: '',
            clicks: 0,
            radius: 0,
            viewType: 'Map'
        };
    }

    componentDidMount() {
        getAffiliations(this)
    }

    searchClick = async () => {
        console.log(this.state)
        const info = await filter_users(this);
        if (info !== -1) {
            this.setState({
                data: info.data,
                clicks: this.state.clicks + 1,
                latitude: info.latitude,
                longitude: info.longitude
            })
        }
    };

    selectBusinessChange = (event, data) => {
        this.setState({business_id: data.value})
    };

    selectListView = event => {
        this.setState({viewType: 'List'})
    };

    selectMapView = event => {
        this.setState({viewType: 'Map'})
    };

    handleGuideClick = event => {
        window.location.href = "/guide"
    };

    handleInputChange = event => {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    };


    render() {
        const { app } = this.props;
        let affiliation_list = this.state.businesses;
        const lang = localStorage.getItem('lang');
        const business_list = affiliation_list.map(business => { return {key: business.value, value: business.value, text: business.labels}});
        return (
            <div>
                {this.state.viewType === 'Map' ? <Home data={this.state} app={app}/> : <BusinessList data={this.state} app={app}/>}
                <div className="formContainer">
                    <div className="column">
                        <label htmlFor="location">{getTranslation(lang, "business_name", "posting_form")}</label>
                        {business_list.length === 0 ? <Dropdown loading/> :
                            <Dropdown
                                placeholder='Select a Business'
                                fluid
                                selection
                                options={business_list}
                                onChange={this.selectBusinessChange}
                                value={this.state.business_id}
                            />}

                        <label htmlFor="location">{getTranslation(lang, "radius_select", "panel")}</label>
                        {this.state.business_id === '' ? <Input disabled placeholder={'Enter a radius'}/> :
                            <Input
                                placeholder='Enter a radius'
                                onChange={this.handleInputChange}
                                type='number'
                                fluid
                                className='input'
                                name='radius'/> }
                        {/*<label htmlFor="location">Filter by Skills</label>*/}
                        {/*<Input*/}
                        {/*    placeholder='Enter Some Skills'*/}
                        {/*    onChange={this.handleInputChange}*/}
                        {/*    type='text'*/}
                        {/*    fluid*/}
                        {/*    className='input'*/}
                        {/*    name='skills'/>*/}
                        <Button onClick={this.searchClick}>{getTranslation(lang, "nearMe_button", "panel")}</Button>
                    </div>
                    <div className="column">
                        <Button id='btn-login-register' onClick={this.selectListView}>{getTranslation(lang, "list_view_button", "panel")}</Button>
                        <Button id='btn-login-register' onClick={this.selectMapView}>{getTranslation(lang, "map_view_button", "panel")}</Button>
                        {/*<Button id='btn-login-register' onClick={this.handleGuideClick}>{getTranslation(lang, "guide", "panel")}</Button>*/}
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(BusinessHome);