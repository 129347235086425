import React from 'react';
import "./index.css"
import { login } from "../../../actions/user";
import { Link } from "react-router-dom";
import {getTranslation} from "../../../languages/language";

class UserLogin extends React.Component {
    constructor(props) {
        super(props);
        this.props.history.push("/user-login")
    }

    state = {
        email: "",
        password: ""
    };

    handleInputChange = event => {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    };

    handleOnClick = event => {
        window.location.href = "/user-register"
    };


    render() {
        const { app } = this.props;
        const lang = localStorage.getItem('lang');
        return (
                <div className="container-login">
                    <div className="login-form">
                        <h1>{getTranslation(lang, "user_title", "login")}</h1>
                        <div className="form-block">
                            <input type="text" name="email" placeholder={getTranslation(lang, "email", "info")} onChange={this.handleInputChange}/><br />
                        </div>
                        <div className="form-block">
                            <input type="text" name="password" placeholder={getTranslation(lang, "password", "info")} onChange={this.handleInputChange}/><br />
                        </div>
                        <button className="btn-login" onClick={() => login(this, app)}>{getTranslation(lang, "submit", "login")}</button><br />
                        <p>{getTranslation(lang, "no_account", "login")}</p><br />
                    
                        <button id="btn-login-register" onClick={this.handleOnClick}>{getTranslation(lang, "register", "login")}</button><br/>
                        <Link to="/business-login">{getTranslation(lang, "login_business", "login")}</Link>
                    </div>
                </div>
            

        )
    }
}

export default UserLogin