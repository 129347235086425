import './index.css'
import React from "react";
import { viewProfile } from '../../actions/user';
import AddressItem from "./AddressItem";
//import SkillItem from "./SkillItem";
import {getTranslation} from "../../languages/language";
import {Segment} from "semantic-ui-react";

class Profile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: []
        }
    }

    componentDidMount() {
        viewProfile(this, this.props.app)
    }

    render() {
        const { app } = this.props;
        const lang = localStorage.getItem('lang');
        return (
            <div>
                <div className="container-profile">
                    <h2>Profile for {this.state.data.name}</h2>
                    <div className="container-info">
                        <Segment>
                            <p>{getTranslation(lang, "email", "info")}: {this.state.data.email}</p>
                            <p>{getTranslation(lang, "number", "info")}: {this.state.data.phone_number}</p>
                            <p>{getTranslation(lang, "date_of_birth", "info")}: {this.state.data.birth_date}</p>
                            <p>{getTranslation(lang, "address", "info")}: </p>
                            <AddressItem app={app} />
                        </Segment>
                    </div>
                </div>
            </div>
        )

    }

}

export default Profile