import React from 'react';
import "./index.css"
import {addPosting, getAffiliations} from "../../actions/business";
import {getTranslation} from "../../languages/language";
import Dropdown from "semantic-ui-react/dist/commonjs/modules/Dropdown";
import {Button, Input, Label, Segment} from "semantic-ui-react";

//import {Link} from "react-router-dom";

class PostingForm extends React.Component {
    state = {
        businesses: [],
        business_id: '',
        business_id_error: false,
        job_title: "",
        job_title_error: false,
        job_description: "",
        job_description_error: false,
        deadline_date: "",
        deadline_date_error: false,
        deadline_time: "",
        deadline_time_error: false,
        job_requirements: "",
        job_requirements_error: false
    };

    componentDidMount() {
        getAffiliations(this)
    }

    selectBusinessChange = (event, data) => {
        this.setState({business_id: data.value})
    };

    handleInputChange = event => {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    };



    render() {
        const { app } = this.props;
        let affiliation_list = this.state.businesses;
        const lang = localStorage.getItem('lang');
        const business_list = affiliation_list.map(business => { return {key: business.value, value: business.value, text: business.labels}});
        return (
            <div className='container2'>
                <div className='login-form'>
                    <Segment textAlign='center'>
                        <h1>{getTranslation(lang, "title", "posting_form")}</h1>
                        <div className="form-block">
                            <label>{getTranslation(lang, "business_name", "posting_form")}</label>
                            {this.state.business_id_error ? <Label basic color='red' pointing='below'>Please choose a business</Label> : <div/>}
                            {business_list.length === 0 ? <Dropdown loading/> :
                                <Dropdown
                                    placeholder={getTranslation(lang, "business_name", "posting_form")}
                                    fluid
                                    selection
                                    options={business_list}
                                    onChange={this.selectBusinessChange}
                                    value={this.state.business_id}
                                    error={this.state.business_id_error}
                                />}
                        </div>
                        <div className="form-block">
                            <label>{getTranslation(lang, "job_title", "posting_form")}</label>
                            {this.state.job_title_error ? <Label basic color='red' pointing='below'>Please enter a job title</Label> : <div/>}
                            <Input fluid name='job_title' type="text" onChange={this.handleInputChange} error={this.state.job_title_error}/>
                        </div>
                        <div className="form-block">
                            <label>{getTranslation(lang, "deadline_date", "posting_form")}</label>
                            {this.state.deadline_date_error ? <Label basic color='red' pointing='below'>Please enter the deadline date with the format YYYY-MM-DD</Label> : <div/>}
                            <Input fluid name='deadline_date' type="text" onChange={this.handleInputChange} error={this.state.deadline_date_error}/>
                        </div>
                        <div className="form-block">
                            <label>{getTranslation(lang, "deadline_time", "posting_form")}</label>
                            {this.state.deadline_time_error ? <Label basic color='red' pointing='below'>Please enter the deadline time with the format hh:mm</Label> : <div/>}
                            <Input fluid name='deadline_time' type="text" onChange={this.handleInputChange} error={this.state.deadline_time_error}/>
                        </div>
                        <div className="form-block">
                            <label>{getTranslation(lang, "job_description", "posting_form")}</label>
                            {this.state.job_description_error ? <Label basic color='red' pointing='below'>Please enter a job description</Label> : <div/>}
                            <Input fluid name='job_description' type="text" onChange={this.handleInputChange} error={this.state.job_description_error}/>
                        </div>
                        <div className="form-block">
                            <label>{getTranslation(lang, "job_requirements", "posting_form")} </label>
                            {this.state.job_requirements_error ? <Label basic color='red' pointing='below'>Please enter the job requirements</Label> : <div/>}
                            <Input fluid name='job_requirements' type="text" onChange={this.handleInputChange} error={this.state.job_requirements_error}/>
                        </div>
                        <Button id="btn-login" onClick={() => addPosting(this)}>{getTranslation(lang, "submit", "login")}</Button><br />
                    </Segment>
                </div>
            </div>


        )
    }
}

export default PostingForm