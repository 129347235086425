import React from 'react';
import "./index.css"
import { addUser } from "../../../actions/user";
import {Link} from "react-router-dom";
import {getTranslation} from "../../../languages/language";
import {Input, Label} from "semantic-ui-react";

class UserRegister extends React.Component {
    state = {
        email: "",
        email_error: false,
        password: "",
        password_error: false,
        phone_number: "",
        phone_number_error: false,
        name: "",
        name_error: false,
        birth_date: "",
        birth_date_error: false,
        address: "",
        address_error: false,
        address_error_message: '',
        city: "",
        city_error: false,
        state: "",
        state_error: false,
        pincode: null,
        pincode_error: false
    };

    handleInputChange = event => {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    };

    handleOnClick = event => {
        window.location.href = "/user-login"
    };


    render() {
        const { app } = this.props;
        const lang = localStorage.getItem('lang');
        return (
            <div>
                <div className="user-container-login">
                        <div className="login-form">
                            <h1>{getTranslation(lang, "user_register", "login")}</h1>
                            <div className="form-block">
                                <label>{getTranslation(lang, "email", "info")}:</label>
                                {this.state.email_error ? <Label basic color='red' pointing='below'>Please enter an email</Label> : <div/>}
                                <Input type="text" name="email" onChange={this.handleInputChange} error={this.state.email_error}/><br />
                            </div>
                            <div className="form-block">
                                <label>{getTranslation(lang, "password", "info")}: </label>
                                {this.state.password_error ? <Label basic color='red' pointing='below'>Please enter your password</Label> : <div/>}
                                <Input type="password" name="password" onChange={this.handleInputChange} error={this.state.password_error}/><br />
                            </div>
                            <div className="form-block">
                                <label>{getTranslation(lang, "name", "info")}: </label>
                                {this.state.name_error ? <Label basic color='red' pointing='below'>Please enter your name</Label> : <div/>}
                                <Input type="text" name="name" onChange={this.handleInputChange} error={this.state.name_error}/><br />
                            </div>
                            <div className="form-block">
                                <label>{getTranslation(lang, "address", "info")}: </label>
                                {this.state.address_error ? <Label basic color='red' pointing='below'>{this.state.address_error_message}</Label> : <div/>}
                                <Input type="text" name="address" onChange={this.handleInputChange} error={this.state.address_error}/><br />
                            </div>
                            <div className="form-block">
                                <label>{getTranslation(lang, "city", "info")}: </label>
                                {this.state.city_error ? <Label basic color='red' pointing='below'>Please enter your city</Label> : <div/>}
                                <Input type="text" name="city" onChange={this.handleInputChange} error={this.state.city_error}/><br />
                            </div>
                            <div className="form-block">
                                <label>{getTranslation(lang, "state", "info")}: </label>
                                {this.state.state_error ? <Label basic color='red' pointing='below'>Please enter your state</Label> : <div/>}
                                <Input type="text" name="state" onChange={this.handleInputChange} error={this.state.state_error}/><br />
                            </div>
                            <div className="form-block">
                                <label>{getTranslation(lang, "pincode", "info")}: </label>
                                {this.state.pincode_error ? <Label basic color='red' pointing='below'>Please enter your pincode</Label> : <div/>}
                                <Input type="number" name="pincode" onChange={this.handleInputChange} error={this.state.pincode_error}/><br />
                            </div>
                            <div className="form-block">
                                <label>{getTranslation(lang, "date_of_birth", "info")}: (YYYY-MM-DD)</label>
                                {this.state.birth_date_error ? <Label basic color='red' pointing='below'>Please enter your birth date with the format YYYY-MM-DD</Label> : <div/>}
                                <Input type="text" name="birth_date" onChange={this.handleInputChange} error={this.state.birth_date_error}/><br />
                            </div>
                            <div className="form-block">
                                <label>{getTranslation(lang, "number", "info")}: </label>
                                {this.state.phone_number_error ? <Label basic color='red' pointing='below'>Please enter your phone number</Label> : <div/>}
                                <Input type="text" name="phone_number" onChange={this.handleInputChange} error={this.state.phone_number_error}/><br />
                            </div>
                            <button id="btn-login" onClick={() => addUser(this, app.state.accessID)}>{getTranslation(lang, "submit", "login")}</button><br />
                            <p>{getTranslation(lang, "have_account", "login")}</p><br />
                        
                            <button id="btn-login-register" onClick={this.handleOnClick}>{getTranslation(lang, "login_button", "login")}</button><br/>
                            <Link to="/business-register">{getTranslation(lang, "business_register", "login")}</Link>
                        </div>
                    
                </div>

            </div>

        )
    }
}

export default UserRegister