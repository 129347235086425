import React from "react";
import BusinessTable from "./BusinessTable";
import PostingTable from "./PostingTable";
import {getTranslation} from "../../languages/language";

class BusinessView extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { app } = this.props;
        const lang = localStorage.getItem('lang');
        return (
            <div>
                <h3>{getTranslation(lang, "business_title", "business_posting_view")}</h3>
                <BusinessTable app={app} lang={lang}/>
                <h3>{getTranslation(lang, "posting_title", "business_posting_view")}</h3>
                <PostingTable app={app} lang={lang}/>
            </div>
        )

    }

}

export default BusinessView