import React from "react";
import "./styles.css";
import {getBusinessPostings, getBusinessInfo} from "../../../actions/postings";
import Posting from "../Posting";
import {getTranslation} from "../../../languages/language";
import {Loader, Dimmer, Tab} from "semantic-ui-react";
import Map from "mapmyindia-react"

class PostingViewTable extends React.Component {
    render() {
        const { app, info } = this.props;
        const lang = localStorage.getItem('lang');
        console.log(this.state)
        return (
            <div className="postingContainer">
                <link rel="stylesheet" href="https://www.w3schools.com/w3css/4/w3.css"/>
                <div className="w3-container">
                    <h2>Business Name: {info.state.business_name}</h2>
                    <table className="w3-table-all">
                        <thead>
                        <tr className="w3-blue">
                            <th width="5%">ID</th>
                            <th>{getTranslation(lang, "job_title", "info")}</th>
                            <th width="50%">{getTranslation(lang, "address", "info")}</th>
                            <th>{getTranslation(lang, "job_deadline", "info")}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {info.state.postings.map(posting => (
                            <Posting posting={posting} business_info={info}/>
                        ))}
                        </tbody>
                    </table>
                </div>
            </div>
        )

    }

}

export default PostingViewTable