// Call mapmyindia api for access token
import ApiUrl from "../api/config"

export const get_access_token = (comp) => {
    const url = ApiUrl + '/mapmyindia/token';
    fetch(url)
        .then(res => {
            return res.json()
        })
        .then(json => {
            comp.setState({ accessID: json.access_token })
        })
        .catch(err => {
            console.log(err)
        })
};

// Returns geocded information from a get request to MapmyIndia's api
export const geocode = (comp) => {
    const access_token = comp.state.accessID;
    const address = comp.state.address;
    console.log(comp);
  if (!access_token) {
      return null
  }
  const url = `https://cors-anywhere.herokuapp.com/https://atlas.mapmyindia.com/api/places/geocode?address=${address}`;
  const request = new Request(url, {
      method: 'get',
      headers: {
          'Authorization': `bearer ${access_token}`
      }
  });
    fetch(request)
        .then(res => {
            return res.json()
        })
        .then(json => {
            console.log(json)
            comp.setState( { addressInfo: {latitude: json.copResults.latitude, longitude: json.copResults.longitude} } )
        })
        .catch(err => {
            console.log(err)
        })
};