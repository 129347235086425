import React from "react";
import { Route, Redirect } from "react-router-dom"

export const BusinessRoute = ({component: Component, ...rest}) => {
    return (
        <Route path={rest.path} render={ ({history}) => (
            localStorage.getItem('role') === 'business' ?
                <Component app={rest.app} history={history}/>
                : <Redirect to="/business-login" />
        )} />
    );
};

export const UserRoute = ({component: Component, ...rest}) => {
    return (
        <Route path={rest.path} render={ ({history}) => (
            localStorage.getItem('role') === 'user' ?
                <Component app={rest.app} history={history}/>
                : <Redirect to="/user-login" />
        )} />
    );
};