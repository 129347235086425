import React from "react";
import {removePosting} from "../../../../actions/views";

class PostingItem extends React.Component {
    render() {
        const { item, list, app } = this.props;
        return (
            <tr>
                <td>
                    {item.job_title}
                </td>
                <td>
                    {item.job_description}
                </td>
                <td>
                    {item.deadline}
                </td>
                <td>
                    {item.created_on}
                </td>
                <td>
                    <button onClick={() => removePosting(list, item, app)}>remove</button>
                </td>
            </tr>
        )
    }
}

export default PostingItem